html {
  scrollbar-gutter: stable;
  height: 100%;
}

body {
  /* font-family: Circular, sans-serif; */
  height: 100%;
}

.bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.data-\[state\=unchecked\]\:bg-input[data-state=unchecked] {
  --tw-bg-opacity: 1;
  background-color: rgb(208 213 221 / var(--tw-bg-opacity));
}

.data-\[state\=checked\]\:bg-primary[data-state=checked] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.search-header:after {
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  transform-origin: 50% 0%;
  height: 100%;
  width: 100%;
  content: '';
  will-change: transform;
  transition: transform 250ms cubic-bezier(0.2,0,0,1);
}

.search-header-full:after {
  --tw-shadow-color: hsl(var(--border));
  --tw-shadow: var(--tw-shadow-colored);
}

.gm-style iframe + div { border: none !important; }

.circle-widget:before,
.circle-widget:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  pointer-events: none;
}


.circle-widget:before {
  background: conic-gradient(from -33deg at 50% 50%,transparent 0deg,rgba(37, 78, 219 ,0.4) 52deg,rgba(37, 78, 219 ,0.4) calc(var(--angle) / 2 + 38deg),transparent calc(var(--angle) / 2 + 38deg + 0.5deg),transparent 100%);
}


.circle-widget:after {
  background: conic-gradient(from calc((var(--angle) / 2) + 5deg - 0.5deg) at 50% 50%,rgba(37, 78, 219 ,0.4) 0deg,rgba(37, 78, 219 ,0.4) calc((var(--angle) / 2) + 2deg),transparent calc(var(--angle) / 2 + 35deg),transparent 100%)
}

.handler {
  position: absolute;
  z-index: 20;
  width: 44px;
  height: calc(50% + -8px);
  transform-origin: center bottom;
  top: 0;
  left: 50%;
}

.handler:before,
.handler:after {
  content: '';
  border-radius: 50%;
  pointer-events: none;
}

.handler:before {
  width: 44px;
  height: 44px;
  box-shadow: 0 2px 2px rgba(37, 78, 219, 0.5),inset 0 -1px 2px rgba(0,0,0,0.35),inset 0 2px 2px #ffffff;
  background: linear-gradient(180deg,#d5d5d5 0%,#ffffff 83.75%);
  right: 0;
  position: absolute;
}


.handler:after {
  transition: scale 0.2s cubic-bezier(0.2,0,0,1);
  transform: translate(2px,2px) rotate(calc(-1 * var(--angle)));
}

.rdrCalendarWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthAndYearWrapper {
  width: 100%;
}

.rdrMonths {
  width: 100%;
  justify-content: center;
  max-width: 752px;
  display: flex;
  flex: 1;
}

.rdrMonth {
  padding: 0 14px !important;
}

@media (min-width: 768px) {
  .rdrMonth {
    padding: 0 27px !important;
  }
}

.rdrWeekDay {
  max-width: 46px !important;
  font-weight: 600 !important;
}

.rdrMonthName {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: rgb(34, 34, 34) !important;
  font-weight: 600;
}

.rdrDays {
  display: flex;
  row-gap: 2px;
  width: 100%;
}

.rdrMonth {
  flex: 1;
}

.rdrMonth .rdrDay {
  height: 46px;
  width: 46px;
}

.rdrMonth .rdrDay .rdrDayNumber {
  border: 1.5px solid transparent !important;
  border-radius: 50% !important;
}

.rdrMonth .rdrDay .rdrDayNumber:hover {
  border-color: black !important;
}

.rdrStartEdge {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
  width: 46px;
  height: 46px;
  background: rgb(247, 247, 247) !important;
}

.rdrStartEdge ~ span {
  position: relative;
  z-index: 10;
}

.rdrInRange {
  border-radius: 0 !important;
  background: rgb(247, 247, 247) !important;
  width: 46px;
  height: 46px;
  top: 0 !important;
  bottom: 0 !important;
}

.rdrInRange ~ span.rdrDayNumber > span {
  color: rgb(34, 34, 34) !important;
}

.rdrEndEdge {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
  width: 46px;
  height: 46px;
  background: #f7f7f7 !important;
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 600 !important;
}

.rdrMonth .rdrDay:hover {
  border-radius: 50% !important;
  top: 0 !important;
  bottom: 0 !important;
}

.rdrDayNumber, .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  right: 0 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  display: flex !important;
}

.rdrStartEdge ~ span,
.rdrEndEdge ~ span {
  border-radius: 9999px;
  height: 46px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 46px;
  border: 1.5px solid rgb(34, 34, 34) !important;
  background: black !important;
}

.rdrDayNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  /* border-radius: 50% !important;
  top: 0 !important;
  bottom: 0 !important;
  border: 1.5px solid black !important; */
  content: none;
  display: none;
}

.rdrMonth .rdrDay .rdrDayNumber {
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.rdrDayToday .rdrDayNumber span:after {
  content: none;
  display: none;
}

.rdrDayPassive {
  opacity: 0 !important;
  visibility: hidden !important;
}

.no-scrollbar {
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
}

.gg-grid > :nth-child(n) {
  grid-area: 1/1/2/2;
}

@media (min-width: 500px) {
  .grid-map-visible {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .grid-map-visible {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* Now map can be visible as well */
@media (min-width: 1024px) {
  .grid-map-visible {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1220px) {
  .grid-map-visible {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1625px) {
  .grid-map-visible {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 2036px) {
  .grid-map-visible {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 2330px) {
  .grid-map-visible {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 2730px) {
  .grid-map-visible {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media (min-width: 3120px) {
  .grid-map-visible {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 3520px) {
  .grid-map-visible {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 3910px) {
  .grid-map-visible {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

/* ==================== */

@media (min-width: 500px) {
  .grid-map-hidden {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 768px) {
  .grid-map-hidden {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .grid-map-hidden {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media (min-width: 1220px) {
  .grid-map-hidden {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (min-width: 1470px) {
  .grid-map-hidden {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

@media (min-width: 1720px) {
  .grid-map-hidden {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

@media (min-width: 1970px) {
  .grid-map-hidden {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}

@media (min-width: 2220px) {
  .grid-map-hidden {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
}

@media (min-width: 2470px) {
  .grid-map-hidden {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}

@media (min-width: 2720px) {
  .grid-map-hidden {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
}

@media (min-width: 2970px) {
  .grid-map-hidden {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}

@media (min-width: 3220px) {
  .grid-map-hidden {
    grid-template-columns: 13;
  }
}

@media (min-width: 3470px) {
  .grid-map-hidden {
    grid-template-columns: 14;
  }
}

@media (min-width: 3720px) {
  .grid-map-hidden {
    grid-template-columns: 15;
  }
}

@media (min-width: 3970px) {
  .grid-map-hidden {
    grid-template-columns: 16;
  }
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Freshdesk chat bot button */
#fc_frame {
  bottom: 50px !important;
  z-index: 50 !important;
}

/* Mobile */
@media only screen and (max-width: 639px) {
  #fc_frame {
    bottom: 12px !important;
    z-index: 50 !important;
  }
  
  #fc_frame.fc-open {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: none !important;
  }

  #fc_frame.fc-open iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

/* Desktop */
@media only screen and (min-width: 640px) {
  #fc_frame {
    right: 36px !important;
  }
}
:root, :host {
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Free';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Free';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Pro';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Pro';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands';
  --fa-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-thin: normal 100 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-duotone-solid: normal 900 1em/1 'Font Awesome 6 Sharp Duotone'; }

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box; }

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-2xs {
    vertical-align: 0.1em; }
  .svg-inline--fa.fa-xs {
    vertical-align: 0em; }
  .svg-inline--fa.fa-sm {
    vertical-align: -0.07143em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.2em; }
  .svg-inline--fa.fa-xl {
    vertical-align: -0.25em; }
  .svg-inline--fa.fa-2xl {
    vertical-align: -0.3125em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-li {
    width: var(--fa-li-width, 2em);
    top: 0.25em; }
  .svg-inline--fa.fa-fw {
    width: var(--fa-fw-width, 1.25em); }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    transform-origin: center center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; }

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-counter-scale, 0.25));
  transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom left; }

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top right; }

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top left; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0)); }

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto); }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black; }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

.rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.rdrDateDisplayItem input{
    text-align: inherit
  }

.rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.rdrDateDisplayItemActive{}

.rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthPicker{}

.rdrYearPicker{}

.rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.rdrPprevButton {}

.rdrNextButton {}

.rdrMonths{
  display: flex;
}

.rdrMonthsVertical{
  flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.rdrMonth{
  width: 27.667em;
}

.rdrWeekDays{
  display: flex;
}

.rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.rdrDateDisplayWrapper{}

.rdrMonthName{}

.rdrInfiniteMonths{
  overflow: auto;
}

.rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDateInput {
  position: relative;
}

.rdrDateInput input {
    outline: none;
  }

.rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.rdrDayNumber {
  display: block;
  position: relative;
}

.rdrDayNumber span{
    color: #1d2429;
  }

.rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
  .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.rdrInRange{}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.rdrDayHovered{}

.rdrDayActive{}

.rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.rdrDefinedRangesWrapper{}

.rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.rdrStaticRange{
  font-size: inherit;
}

.rdrStaticRangeLabel{}

.rdrInputRanges{}

.rdrInputRange{
  display: flex;
}

.rdrInputRangeInput{}

.rdrCalendarWrapper{
  color: #000000;
  font-size: 12px;
}

.rdrDateDisplayWrapper{
  background-color: rgb(239, 242, 247);
}

.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayItem{
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 1px 2px 0 rgba(35, 57, 66, 0.21);
  border: 1px solid transparent;
}

.rdrDateDisplayItem input{
    cursor: pointer;
    height: 2.5em;
    line-height: 2.5em;
    border: 0px;
    background: transparent;
    width: 100%;
    color: #849095;
  }

.rdrDateDisplayItemActive{
  border-color: currentColor;
}

.rdrDateDisplayItemActive input{
    color: #7d888d
  }

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
}

.rdrMonthAndYearPickers{
  font-weight: 600;
}

.rdrMonthAndYearPickers select{
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    padding: 10px 30px 10px 10px;
    border-radius: 4px;
    outline: 0;
    color: #3e484f;
    background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    text-align: center
  }

.rdrMonthAndYearPickers select:hover{
      background-color: rgba(0,0,0,0.07);
    }

.rdrMonthPicker, .rdrYearPicker{
  margin: 0 5px
}

.rdrNextPrevButton {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0.833em;
  padding: 0;
  border: 0;
  border-radius: 5px;
  background: #EFF2F7
}

.rdrNextPrevButton:hover{
    background: #E1E7F0;
  }

.rdrNextPrevButton i {
    display: block;
    width: 0;
    height: 0;
    padding: 0;
    text-align: center;
    border-style: solid;
    margin: auto;
    transform: translate(-3px, 0px);
  }

.rdrPprevButton i {
    border-width: 4px 6px 4px 4px;
    border-color: transparent rgb(52, 73, 94) transparent transparent;
    transform: translate(-3px, 0px);
  }

.rdrNextButton i {
    margin: 0 0 0 7px;
    border-width: 4px 4px 4px 6px;
    border-color: transparent transparent transparent rgb(52, 73, 94);
    transform: translate(3px, 0px);
  }

.rdrWeekDays {
  padding: 0 0.833em;
}

.rdrMonth{
  padding: 0 0.833em 1.666em 0.833em;
}

.rdrMonth .rdrWeekDays {
    padding: 0;
  }

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
  display: none;
}

.rdrWeekDay {
  font-weight: 400;
  line-height: 2.667em;
  color: rgb(132, 144, 149);
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429
}

.rdrDay:focus {
    outline: 0;
  }

.rdrDayNumber {
  outline: 0;
  font-weight: 300;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  top: 5px;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDayToday .rdrDayNumber span{
  font-weight: 500
}

.rdrDayToday .rdrDayNumber span:after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: #3d91ff;
  }

.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after,.rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after{
      background: #fff;
    }

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
          color: rgba(255, 255, 255, 0.85);
        }

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: currentColor;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 5px;
}

.rdrSelected{
  left: 2px;
  right: 2px;
}

.rdrInRange{}

.rdrStartEdge{
  border-top-left-radius: 1.042em;
  border-bottom-left-radius: 1.042em;
  left: 2px;
}

.rdrEndEdge{
  border-top-right-radius: 1.042em;
  border-bottom-right-radius: 1.042em;
  right: 2px;
}

.rdrSelected{
  border-radius: 1.042em;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    left: 2px;
  }

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    right: 2px;
  }

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
    border-top-left-radius: 1.333em;
    border-bottom-left-radius: 1.333em;
    border-left-width: 1px;
    left: 0px;
  }

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
   border-top-right-radius: 1.333em;
   border-bottom-right-radius: 1.333em;
   border-right-width: 1px;
   right: 0px;
 }

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  border: 0px solid currentColor;
  z-index: 1;
}

.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  left: 0px;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 0px;
}

.rdrDefinedRangesWrapper{
  font-size: 12px;
  width: 226px;
  border-right: solid 1px #eff2f7;
  background: #fff;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected{
    color: currentColor;
    font-weight: 600;
  }

.rdrStaticRange{
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  border-bottom: 1px solid #eff2f7;
  padding: 0;
  background: #fff
}

.rdrStaticRange:hover .rdrStaticRangeLabel,.rdrStaticRange:focus .rdrStaticRangeLabel{
      background: #eff2f7;
    }

.rdrStaticRangeLabel{
  display: block;
  outline: 0;
  line-height: 18px;
  padding: 10px 20px;
  text-align: left;
}

.rdrInputRanges{
  padding: 10px 0;
}

.rdrInputRange{
  align-items: center;
  padding: 5px 20px;
}

.rdrInputRangeInput{
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  text-align: center;
  border: solid 1px rgb(222, 231, 235);
  margin-right: 10px;
  color: rgb(108, 118, 122)
}

.rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
    border-color: rgb(180, 191, 196);
    outline: 0;
    color: #333;
  }

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
  content: '';
  border: 1px solid currentColor;
  border-radius: 1.333em;
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: 0px;
  right: 0px;
  background: transparent;
}

.rdrDayPassive{
  pointer-events: none;
}

.rdrDayPassive .rdrDayNumber span{
    color: #d5dce0;
  }

.rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
    display: none;
  }

.rdrDayDisabled {
  background-color: rgb(248, 248, 248);
}

.rdrDayDisabled .rdrDayNumber span{
    color: #aeb9bf;
  }

.rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
    filter: grayscale(100%) opacity(60%);
  }

.rdrMonthName{
  text-align: left;
  font-weight: 600;
  color: #849095;
  padding: 0.833em;
}

