html {
  scrollbar-gutter: stable;
  @apply h-full;
}

body {
  /* font-family: Circular, sans-serif; */
  @apply h-full;
}

.bg-background {
  @apply bg-white
}

.data-\[state\=unchecked\]\:bg-input[data-state=unchecked] {
  @apply bg-gray-300;
}

.data-\[state\=checked\]\:bg-primary[data-state=checked] {
  @apply bg-black
}

.search-header:after {
  position: absolute;
  background-color: white;
  left: 0;
  top: 0;
  transform-origin: 50% 0%;
  height: 100%;
  width: 100%;
  content: '';
  will-change: transform;
  transition: transform 250ms cubic-bezier(0.2,0,0,1);
}

.search-header-full:after {
  @apply shadow-border;
}

.gm-style iframe + div { border: none !important; }

.circle-widget:before,
.circle-widget:after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: '';
  border-radius: 50%;
  pointer-events: none;
}


.circle-widget:before {
  background: conic-gradient(from -33deg at 50% 50%,transparent 0deg,rgba(37, 78, 219 ,0.4) 52deg,rgba(37, 78, 219 ,0.4) calc(var(--angle) / 2 + 38deg),transparent calc(var(--angle) / 2 + 38deg + 0.5deg),transparent 100%);
}


.circle-widget:after {
  background: conic-gradient(from calc((var(--angle) / 2) + 5deg - 0.5deg) at 50% 50%,rgba(37, 78, 219 ,0.4) 0deg,rgba(37, 78, 219 ,0.4) calc((var(--angle) / 2) + 2deg),transparent calc(var(--angle) / 2 + 35deg),transparent 100%)
}

.handler {
  position: absolute;
  z-index: 20;
  width: 44px;
  height: calc(50% + -8px);
  transform-origin: center bottom;
  top: 0;
  left: 50%;
}

.handler:before,
.handler:after {
  content: '';
  border-radius: 50%;
  pointer-events: none;
}

.handler:before {
  width: 44px;
  height: 44px;
  box-shadow: 0 2px 2px rgba(37, 78, 219, 0.5),inset 0 -1px 2px rgba(0,0,0,0.35),inset 0 2px 2px #ffffff;
  background: linear-gradient(180deg,#d5d5d5 0%,#ffffff 83.75%);
  right: 0;
  position: absolute;
}


.handler:after {
  transition: scale 0.2s cubic-bezier(0.2,0,0,1);
  transform: translate(2px,2px) rotate(calc(-1 * var(--angle)));
}

.rdrCalendarWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rdrMonthAndYearWrapper {
  width: 100%;
}

.rdrMonths {
  width: 100%;
  justify-content: center;
  max-width: 752px;
  display: flex;
  flex: 1;
}

.rdrMonth {
  padding: 0 14px !important;
}

@screen md {
  .rdrMonth {
    padding: 0 27px !important;
  }
}

.rdrWeekDay {
  max-width: 46px !important;
  font-weight: 600 !important;
}

.rdrMonthName {
  display: flex;
  justify-content: center;
  @apply text-base;
  color: rgb(34, 34, 34) !important;
  @apply font-semibold;
}

.rdrDays {
  display: flex;
  @apply gap-y-[2px];
  @apply w-full;
}

.rdrMonth {
  flex: 1;
}

.rdrMonth .rdrDay {
  height: 46px;
  width: 46px;
}

.rdrMonth .rdrDay .rdrDayNumber {
  border: 1.5px solid transparent !important;
  border-radius: 50% !important;
}

.rdrMonth .rdrDay .rdrDayNumber:hover {
  border-color: black !important;
}

.rdrStartEdge {
  @apply !rounded-l-full;
  width: 46px;
  height: 46px;
  background: rgb(247, 247, 247) !important; 
}

.rdrStartEdge ~ span {
  position: relative;
  z-index: 10;
}

.rdrInRange {
  border-radius: 0 !important;
  background: rgb(247, 247, 247) !important;
  width: 46px;
  height: 46px;
  top: 0 !important;
  bottom: 0 !important;
}

.rdrInRange ~ span.rdrDayNumber > span {
  color: rgb(34, 34, 34) !important;
}

.rdrEndEdge {
  @apply !rounded-r-full;
  width: 46px;
  height: 46px;
  background: #f7f7f7 !important; 
}

.rdrDayToday .rdrDayNumber span {
  font-weight: 600 !important;
}

.rdrMonth .rdrDay:hover {
  border-radius: 50% !important;
  top: 0 !important;
  bottom: 0 !important;
}

.rdrDayNumber, .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
  right: 0 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  display: flex !important;
}

.rdrStartEdge ~ span,
.rdrEndEdge ~ span {
  @apply rounded-full;
  height: 46px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 46px;
  border: 1.5px solid rgb(34, 34, 34) !important;
  background: black !important;
}

.rdrDayNumber {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  /* border-radius: 50% !important;
  top: 0 !important;
  bottom: 0 !important;
  border: 1.5px solid black !important; */
  content: none;
  display: none;
}

.rdrMonth .rdrDay .rdrDayNumber {
  font-weight: 600 !important;
  @apply !text-sm;
}

.rdrDayToday .rdrDayNumber span:after {
  content: none;
  display: none;
}

.rdrDayPassive {
  opacity: 0 !important;
  visibility: hidden !important;
}

.no-scrollbar {
  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
}

.gg-grid > :nth-child(n) {
  grid-area: 1/1/2/2;
}

@media (min-width: 500px) {
  .grid-map-visible {
    @apply grid-cols-1;
  }
}

@media (min-width: 500px) {
  .grid-map-visible {
    @apply grid-cols-2;
  }
}

@media (min-width: 768px) {
  .grid-map-visible {
    @apply grid-cols-3;
  }
}

/* Now map can be visible as well */
@media (min-width: 1024px) {
  .grid-map-visible {
    @apply grid-cols-2;
  }
}

@media (min-width: 1220px) {
  .grid-map-visible {
    @apply grid-cols-3;
  }
}

@media (min-width: 1625px) {
  .grid-map-visible {
    @apply grid-cols-4;
  }
}

@media (min-width: 2036px) {
  .grid-map-visible {
    @apply grid-cols-5;
  }
}

@media (min-width: 2330px) {
  .grid-map-visible {
    @apply grid-cols-6;
  }
}

@media (min-width: 2730px) {
  .grid-map-visible {
    @apply grid-cols-7;
  }
}

@media (min-width: 3120px) {
  .grid-map-visible {
    @apply grid-cols-8;
  }
}

@media (min-width: 3520px) {
  .grid-map-visible {
    @apply grid-cols-9;
  }
}

@media (min-width: 3910px) {
  .grid-map-visible {
    @apply grid-cols-10;
  }
}

/* ==================== */

@media (min-width: 500px) {
  .grid-map-hidden {
    @apply grid-cols-1;
  }
}

@media (min-width: 500px) {
  .grid-map-hidden {
    @apply grid-cols-2;
  }
}

@media (min-width: 768px) {
  .grid-map-hidden {
    @apply grid-cols-3;
  }
}

@media (min-width: 1024px) {
  .grid-map-hidden {
    @apply grid-cols-4;
  }
}

@media (min-width: 1220px) {
  .grid-map-hidden {
    @apply grid-cols-5;
  }
}

@media (min-width: 1470px) {
  .grid-map-hidden {
    @apply grid-cols-6;
  }
}

@media (min-width: 1720px) {
  .grid-map-hidden {
    @apply grid-cols-7;
  }
}

@media (min-width: 1970px) {
  .grid-map-hidden {
    @apply grid-cols-8;
  }
}

@media (min-width: 2220px) {
  .grid-map-hidden {
    @apply grid-cols-9;
  }
}

@media (min-width: 2470px) {
  .grid-map-hidden {
    @apply grid-cols-10;
  }
}

@media (min-width: 2720px) {
  .grid-map-hidden {
    @apply grid-cols-11;
  }
}

@media (min-width: 2970px) {
  .grid-map-hidden {
    @apply grid-cols-12;
  }
}

@media (min-width: 3220px) {
  .grid-map-hidden {
    @apply grid-cols-[13];
  }
}

@media (min-width: 3470px) {
  .grid-map-hidden {
    @apply grid-cols-[14];
  }
}

@media (min-width: 3720px) {
  .grid-map-hidden {
    @apply grid-cols-[15];
  }
}

@media (min-width: 3970px) {
  .grid-map-hidden {
    @apply grid-cols-[16];
  }
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Freshdesk chat bot button */
#fc_frame {
  bottom: 50px !important;
  z-index: 50 !important;
}

/* Mobile */
@media only screen and (max-width: 639px) {
  #fc_frame {
    bottom: 12px !important;
    z-index: 50 !important;
  }
  
  #fc_frame.fc-open {
    width: 100% !important;
    height: 100% !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    transform: none !important;
  }

  #fc_frame.fc-open iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

/* Desktop */
@media only screen and (min-width: 640px) {
  #fc_frame {
    right: 36px !important;
  }
}